#Student {
  padding: 1rem 16px 4px;
  margin: -1.5rem -20px 0;
  position: sticky;
  top: 9.25rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
  background-color: var(--#{$prefix}body-bg);
  z-index: 3;

  & ~ section .tablesorter-default thead {
    top: 18.55rem;
  }

  + section {
    margin-top: 1.5rem;
  }

  .sc {
    &:before {
      content: " ";
    }
    font-variant-caps: small-caps;
  }

  h5, h6 {
    margin-bottom: 0.5rem;
  }

  img {
    max-height: 7rem;
    &.empty {
      //border: .25rem solid #020202;
      border-radius: .5rem;
    }
  }
}