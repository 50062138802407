:root,
[data-bs-theme="light"] {

  --#{$prefix}header-bg: #{$header-bg};

  --#{$prefix}sidebar-bg: #{$sidebar-bg};
  --#{$prefix}sidebar-color: #{$sidebar-color};
  --#{$prefix}sidebar-span-color: #{$sidebar-span-color};

  --#{$prefix}sidebar-hover-bg: #{$sidebar-hover-bg};
  --#{$prefix}sidebar-hover-color: #{$sidebar-hover-color};

  --#{$prefix}tablesorter-odd-hover-bg: #{$tablesorter-odd-hover-bg};
  --#{$prefix}tablesorter-even-hover-bg: #{$tablesorter-even-hover-bg};

  --#{$prefix}primary_25: #{$primary_25};
  --#{$prefix}primary-15: #{$primary-15};
  --#{$prefix}primary-25: #{$primary-25};

  --#{$prefix}unsorted-arrow: #{$unsorted-arrow};
  --#{$prefix}sort-asc-arrow: #{$sort-asc-arrow};
  --#{$prefix}sort-desc-arrow: #{$sort-desc-arrow};

  --#{$prefix}breadcrumb-bg: #{$breadcrumb-bg};
  --#{$prefix}breadcrumb-item-active-color: #{$breadcrumb-item-active-color};

}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    color-scheme: dark;

    --#{$prefix}dark-text-emphasis: #{$body-color};
    
    --#{$prefix}header-bg: #{$header-bg-dark};

    --#{$prefix}sidebar-bg: #{$sidebar-bg-dark};
    --#{$prefix}sidebar-color: #{$sidebar-color-dark};
    --#{$prefix}sidebar-span-color: #{$sidebar-span-color-dark};

    --#{$prefix}sidebar-hover-bg: #{$sidebar-hover-bg-dark};
    --#{$prefix}sidebar-hover-color: #{$sidebar-hover-color-dark};

    --#{$prefix}tablesorter-odd-hover-bg: #{$tablesorter-odd-hover-bg-dark};
    --#{$prefix}tablesorter-even-hover-bg: #{$tablesorter-even-hover-bg-dark};

    --#{$prefix}primary_25: #{$primary_25-dark};
    --#{$prefix}primary-15: #{$primary-15-dark};
    --#{$prefix}primary-25: #{$primary-25-dark};

    --#{$prefix}unsorted-arrow: #{$unsorted-arrow-dark};
    --#{$prefix}sort-asc-arrow: #{$sort-asc-arrow-dark};
    --#{$prefix}sort-desc-arrow: #{$sort-desc-arrow-dark};

    --#{$prefix}breadcrumb-bg: #{$breadcrumb-bg-dark};
    --#{$prefix}breadcrumb-item-active-color: #{$breadcrumb-item-active-color-dark};

    .btn-outline-dark {
      color: white;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      color: $breadcrumb-divider-color-dark;
    }
    
    .bg-light section {
      color: $body-color;
    }
  }
}

@include media-breakpoint-up(sm) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
  }
}

.bg-purple {
  --bg-opacity: 1;
  background-color: rgba($purple, var(--bg-opacity)) !important;
}

.table-secondary > .bg-purple {
  --bg-opacity: 1 !important;
  background-color: #defaf5 !important;
}

@include media-breakpoint-down(sm) {
  #basic-navbar-nav {
    margin-left: calc(#{$sidebar-mini-width} + 16px);
  }
}

.breadcrumb {
  font-size: 0.78125rem !important;
  padding: 2px 0.25rem 6px !important;
}

.form-group {
  margin-bottom: .5rem;
}

.form-label {
  margin-bottom: 0;
  font-size: 0.85rem;
}

.required::after {
  content: " *";
  color: #dc3545;
  line-height: 1rem;
  vertical-align: top;
  margin-left: .25rem;
  display: inline-block;
}

.custom-file-label,
.form-control {
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
}

.btn {
  font-weight: 600;
}