input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  //background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--#{$prefix}gray-400);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &.form-check-input {
    margin-top: .2em !important;
  }

  &:focus {
    border-color: #0b85ff;
    box-shadow: 0 0 0 0.1rem rgba(0, 69, 138, 0.25);
  }
}




:not(.form-switch) {
  > input[type="checkbox"] {
    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      //box-shadow: inset 1em 1em var(--form-control-color);
      background-color: $primary;
      //transform-origin: bottom left;
      //clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:disabled {
      opacity: unset;
    }
  }

  &:not(.form-check-filter) > input[type="checkbox"] {
    &:checked::before {
      transform: scale(1);
    }

    &.form-check-input:checked {
      background-color: unset;
      border-color: var(--#{$prefix}gray-400);
    }
  }
}



.form-check {
  padding-left: 2.5em;

  &:not(.form-switch) .form-check-input {
    margin-left: -2em;
  }
}

