.navbar {
  padding: calc(34px + 0.625rem) 0 0.625rem;
  width: 100%;
  justify-content: initial;

  img {
    height: 54px;
  }

  .navbar-nav {
    margin-left: auto;
  }

  .navbar-toggler {
    font-size: 0.78125rem;
    padding: 0.25rem 0.46875rem;
    margin-left: auto;
    margin-right: 16px;
  }

  #basic-navbar-nav {
    margin-right: 16px;
  }

  .nav-link {
    color: var(--#{$prefix}primary-text-emphasis);
    font-family: "Montserrat", sans-serif;
    font-size: 0.6875rem;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;

    > span:last-child {
      padding-left: 0.5rem;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  .navbar-brand {
    padding: 3.64px 0;
    color: var(--#{$prefix}body-color) !important;
    text-transform: lowercase;
    margin-left: calc(#{$sidebar-width} + 14px);
    transition: margin .3s ease;

    h3 {
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      line-height: 2rem;
      font-size: 1.875rem;
      display: inline-block;
      vertical-align: middle;
      padding-right: .3125rem;
      margin-bottom: 0.625rem;
    }
  }
}