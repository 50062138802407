#FichesUE {
  > div {
    margin-bottom: 2rem;
  }

  small + div {
    margin-top: 0.75rem;
  }

  form > div {
    margin-bottom: 1.5rem;
  }
}