@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Merriweather");

@font-face {
  font-family: "CircularStd-Black";
  src: url("../../fonts/CircularStd/CircularStd-Black.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-BlackItalic";
  src: url("../../fonts/CircularStd/CircularStd-BlackItalic.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-Bold";
  src: url("../../fonts/CircularStd/CircularStd-Bold.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-BoldItalic";
  src: url("../../fonts/CircularStd/CircularStd-BoldItalic.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-BoldItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("../../fonts/CircularStd/CircularStd-Book.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-BookItalic";
  src: url("../../fonts/CircularStd/CircularStd-BookItalic.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-Medium";
  src: url("../../fonts/CircularStd/CircularStd-Medium.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-MediumItalic";
  src: url("../../fonts/CircularStd/CircularStd-MediumItalic.woff2") format("woff2"),
  url("../../fonts/CircularStd/CircularStd-MediumItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
