.breadcrumb {
  background: none;
  padding: 1rem 0.25rem 2rem;
  font-size: 1.25rem;
}

[aria-label="breadcrumb"] {
  background-color: var(--#{$prefix}breadcrumb-bg);
  color: var(--#{$prefix}breadcrumb-divider-color);
  padding: 1.8rem 16px 4px;
  margin: -2rem -20px 1rem -12px;
  position: sticky;
  top: 6rem;
  box-shadow: 0 .25rem .25rem rgba(0,0,0,0.15);
  z-index: 5;
}