body {
  font-family: $ff-body;
  line-height: 1.5;
  margin: 0;
}

main {
  max-width: calc(100% - #{$sidebar-width} );
  width: calc(100% - #{$sidebar-width});
  margin-left: $sidebar-width !important;
  padding: 8rem 20px 0 12px !important;
  transition: width .3s ease, max-width .3s ease, margin-left .3s ease;
  position: relative;

  section {
    padding-left: 8px;

    &:last-of-type {
      padding-bottom: 1rem;
    }
  }
}

.flip.animated {
  transform: rotateX(180deg) scaleY(-1);
  transition: transform 0.6s;
}