header {
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--#{$prefix}header-bg);
  box-shadow: 0 0.05rem 1rem rgba(0, 0, 0, .15);

  > div {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    transition: all .5s ease;

    > div {
      background-color: var(--APP_COLOR);
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      color: white;
      font-size: 0.75rem;
      font-family: "Montserrat", sans-serif;
      height: 34px;

      &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        width: 9999px;
        height: 100%;
        top: 0;
        right: 100%;
        background-color: var(--APP_COLOR);
      }

      &:before {
        left: 100%;
        right: initial;
      }


      .social {
        padding: 7px 0 9px;
        margin-right: 12px;

        small {
          padding-right: 0.3rem;
          font-size: 80%;
        }
      }

      a {
        color: $secondary;

        &:hover {
          color: darken($secondary, 25%),
        }

        margin-right: 8px;
      }
    }
  }

}