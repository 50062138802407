@import "~react-toastify/scss/main";

:root {
  --toastify-color-info: #{$secondary};
  --toastify-color-success: #{$success};
  --toastify-color-warning: #{$warning};
  --toastify-color-error: #{$alert};
  --toastify-color-dark: #{$dark};
  --toastify-text-color-info: #{$body-bg-dark};
}

.#{$rt-namespace}__toast-container--top-right {
  top: 2.4em;
}