#SearchBar {
  width: 100%;
  padding-left: .5rem;

  button:focus {
    box-shadow: initial;
  }

  #Shortcut {
    display: flex;
    align-items: center;
  }

  > div {
    padding: 0;
    transition: display .3s ease;
  }

  input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid var(--#{$prefix}primary-text-emphasis);
    border-radius: 0;
    padding-left: 0;
    outline: none;
    box-shadow: none;
    color: var(--#{$prefix}primary-text-emphasis);
  }
}