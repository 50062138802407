.editPanel main > section:not(#Student) {
  max-width: calc(100% - #{$offcanvas-horizontal-width} - 20px);
  width: calc(100% - #{$offcanvas-horizontal-width} - 20px);
}

.offcanvas.offcanvas-end {
  z-index: 4;
  top: calc(11rem - 24px);
  box-shadow: $offcanvas-box-shadow;
  border-top: $offcanvas-border-width solid $offcanvas-border-color;
  transition-timing-function: ease;
}

.offcanvas-title {
  margin: 0 !important;
}

.offcanvas-header .btn-close {
  margin-bottom: 0;

  &:hover {
    background-color: rgb(237, 237, 237);
  }
}

.offcanvas-body {
  padding-top: 0;

  > :first-child {
    position: relative;
    min-height: 100%;
    padding-bottom: 4rem;

    &.canDelete {
      padding-bottom: 6rem;
    }

    div.buttons {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}