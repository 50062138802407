// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-family: "CircularStd-Black", sans-serif;;
  color: var(--#{$prefix}dark-text-emphasis);

  &.darkTheme {
    color: var(--#{$prefix}primary-text-emphasis);
  }
}

h2,
.h2 {
  font-family: $ff-body-bold;
  color: var(--#{$prefix}primary-text-emphasis)
}

h3,
.h3 {
  font-family: $ff-body-bold;
  color: var(--#{$prefix}primary-text-emphasis)
}

h4,
.h4 {
}

h5,
.h5 {
}

h6,
.h6 {
}

p,
ul,
ol {
  margin-top: 0;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: var(--#{$prefix}primary-text-emphasis);
  text-decoration: none;

  &:hover {
    color: var(--#{$prefix}primary-15);
    text-decoration: underline;
  }
}

.text-purple {
  color: $purple;
  font-weight: bold;
}

.text-green {
  color: $green;
  font-weight: bold;
}
