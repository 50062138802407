$color-mode-type: media-query;

nav > section {
  padding-left: 0.25rem;
}

header {
  > div > nav > aside {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: var(--#{$prefix}body-color);
    width: $sidebar-width;
    flex-grow: 0;
    flex-shrink: 0;
    transition: width .3s ease, background-color .3s ease, color .3s ease;
    padding-right: 1rem;
    height: 100%;
    position: absolute;

    &:hover {
      background: $sidebar-aside-hover-bg;
      color: $sidebar-aside-hover-color;
    }
  }

  > aside {
    position: fixed;
    left: 0;
    min-height: 101%;

    transition: width .3s ease;

    &:hover {
      z-index: 4;
    }

    & li.active {
      border-left: 4px solid $white;
    }

    svg, i, .fa-layers {
      height: 1em !important;
      font-size: 1.5em;
      margin-right: 0 !important;
      margin-left: 0 !important;
      flex-shrink: 0;
      flex-basis: calc(#{$sidebar-mini-width} - (8px * 2) - 2px);
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      width: $sidebar-width;
      min-height: 100%;
      background-color: var(--#{$prefix}sidebar-bg);
      transition: all .3s ease;

      a:hover {
        text-decoration: none;
      }

      li {
        width: $sidebar-width;
        white-space: nowrap;
        overflow: hidden;
        transition: background-color, width .3s ease;
        display: flex;

        span {
          transition: color .1s ease;
          color: var(--#{$prefix}sidebar-span-color);
          margin-left: 1rem;
        }

        &:hover > div, &:hover > a {
          background-color:var(--#{$prefix}sidebar-hover-bg);

          svg, i, .fa-layers, span {
            color: var(--#{$prefix}sidebar-hover-color) !important;
          }
        }
      }
    }

    > ul {
      position: absolute;
      box-shadow: 0 36px 2.5rem rgba(0,0,0,.15);

      &:after {
        content: " ";
        height: 190px;
        width: $sidebar-width;
        background: var(--#{$prefix}sidebar-bg);
        display: inline-block;
        transition: all .3s ease;
      }

      > li {
        flex-direction: column;

        > div, > a {
          display: flex;
          transition: background-color .3s ease;
          align-items: center;
          padding: 8px;
          padding-left: 10px;

          > svg, i, .fa-layers {
            color: var(--#{$prefix}sidebar-color);
          }
        }

        &.active > a {
          padding-left: 6px;
        }

        > ul li {
          flex-direction: row;
          align-items: center;
          padding: 8px;
          padding-left: 10px;
          transition: padding 0s ease;

          &.active {
            padding-left: 6px;
          }

          &:not(:hover) {
            svg, i, .fa-layers {
              color: $sidebar-subitem-color;
            }
          }

          &:hover {
            color: $sidebar-subitem-hover-color;
            background-color: $sidebar-subitem-hover-bg;

            span {
              color: $sidebar-subitem-hover-color !important;
            }
          }
        }
      }
    }
  }
}

.sidebar-mini {
  main {
    max-width: calc(100% - #{$sidebar-mini-width});
    width: calc(100% - #{$sidebar-mini-width});
    margin-left: $sidebar-mini-width !important;
  }
  
  header > aside {
    ul {
      width: $sidebar-mini-width;

      &:after {
        width: $sidebar-mini-width;
      }

      li {
        width: $sidebar-mini-width;

        &:hover {
          width: $sidebar-width;
          //border-radius: 0 0.5rem 0.5rem 0;

          > div {
            //border-radius: 0 0.5rem 0.5rem 0;
            color: $sidebar-hover-color;
          }
        }
      }
    }
  }

  header > div > nav > aside {
    width: $sidebar-mini-width;
    justify-content: center;
    padding-right: inherit;
  }

  .navbar-brand {
    margin-left: calc(#{$sidebar-mini-width} + 14px);
  }
}

.no-sidebar {
  header nav aside, #SearchBar {
    display: none !important;
  }

  [aria-label="breadcrumb"] {
    margin-right: 0 !important;
    padding-left: 2.5rem !important;
  }
  
  .navbar-brand {
    margin-left: 2rem;
  }
  
  main {
    padding: 8rem 0 0 !important;
    max-width: initial;
    width: initial;
    margin-left: initial !important;
    transition: none !important;
    
    > div {
      padding: 0 2rem;
    }
  }
}