$prefix: '';

$ff-body: "CircularStd-Book", Arial, sans-serif;
$ff-body-medium: "CircularStd-Medium", sans-serif;
$ff-body-bold: "CircularStd-Bold", sans-serif;
$ff-body-black: "CircularStd-Black", sans-serif;
$ff2: "Montserrat", sans-serif;
$ff-serif: "Merriweather", serif;
$font-base: 62.5%;
$small-font-size: 75%;

$border-color: #dee2e6;
$table-group-separator-color: $border-color;

$xs: 0;
$sm: 540px;
$md: 768px;
$lg: 990px;
$xl: 1200px;
$xxl: 1440px;

$primary: #00458a;
$primary-text-emphasis: $primary;

$primary_25: lighten($primary, 25%);
$primary-15: darken($primary, 15%);
$primary-25: darken($primary, 25%);

$secondary: #5be8cf;
$secondary-text-emphasis: $secondary;

$light: #ecece2;
$xlight: #f3f3f3;
$medium: #abacac;
$dark: #343a40;
$pink: #da2c97;
$bluegreen: #63d7c3;
$xlightblue: #f7f9fc;
$success: #19723b;
$warning: #db8408;
$purple: #8d2e8a;
$green: #78a632;
$orange: #ed971c;
$alert: #c01a1a;
$color-body: #212529;

$sidebar-mini-width: 46px;
$sidebar-width: 240px;

$header-bg: white;
$sidebar-bg: #212529; //#fcfcfc
$sidebar-color: white; //$primary
$sidebar-span-color: white; //$color-body
$sidebar-hover-bg: $primary; //#222d32
$sidebar-hover-color: white;
$sidebar-aside-hover-bg: $primary;
$sidebar-aside-hover-color: white;

$sidebar-subitem-color: $secondary;
$sidebar-subitem-hover-bg: $secondary; //#07090f
$sidebar-subitem-hover-color: $color-body;

$fa-fw-width: 1em;
$offcanvas-horizontal-width: 33.33%;
$offcanvas-box-shadow: 0 36px 2.5rem rgba(0, 0, 0, 0.15);

$btn-font-size: .85rem;
$btn-padding-y: .25rem;
$btn-link-hover-color: #001f3e;

$input-btn-focus-width: .1rem;
//$input-focus-border-color: tint-color($primary, 50%); //#0a85ff = 6x plus bas dans coolors pour #00458a
//$input-hover-border-color: tint-color($primary, 70%);

$breadcrumb-bg: $light; //--> top rider
$breadcrumb-divider-color: var(--#{$prefix}gray-700);
$breadcrumb-item-active-color: var(--#{$prefix}gray-700);

$tablesorter-odd-hover-bg: desaturate(#ff0000, 60%);
$tablesorter-odd-hover-bg: desaturate(white, 60%);
$tablesorter-even-hover-bg: lighten(desaturate(white, 60%), 10%);

/* black */
$unsorted-arrow: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
$sort-asc-arrow: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
$sort-desc-arrow: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
